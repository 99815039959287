<template>
  <div class="relative flex justify-center items-center">
    <div id="menu" class="w-full h-full bg-gray-900 bg-opacity-80 top-0 fixed sticky-0">
      <div class="flex justify-center items-center h-full">
        <div class="md:w-auto dark:bg-gray-800 relative flex flex-col bg-white rounded-lg">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#000" viewBox="0 0 24 24" stroke="#fff" class="w-8 h-8 cloase-btn absolute top-5 right-3 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>

          <div class="title py-4 px-4 md:px-5 xl:py-3 xl:px-5">Edit Intro</div>
          <form class="w-full max-w-lg py-4 px-4 md:px-5 xl:py-3 xl:px-5">
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <input
                  type="text"
                  placeholder="First Name *"
                  class="appearance-none border border-black rounded-3xl w-full py-2 px-3 mt-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
              </div>

              <div class="w-full md:w-1/2 px-3">
                <input
                  type="text"
                  placeholder="Last Name *"
                  class="appearance-none border border-black rounded-3xl w-full py-2 px-3 mt-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <input
                  type="text"
                  placeholder="Heading *"
                  class="appearance-none border border-black rounded-3xl w-full py-2 px-3 mt-0 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <input
                  type="text"
                  placeholder="Heading *"
                  class="appearance-none border border-black rounded-3xl w-full py-2 px-3 mt-0 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <input
                  type="text"
                  placeholder="Heading *"
                  class="appearance-none border border-black rounded-3xl w-full py-2 px-3 mt-0 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <input
                  type="text"
                  placeholder="Heading *"
                  class="appearance-none border border-black rounded-3xl w-full py-2 px-3 mt-0 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'consultant_profile',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
